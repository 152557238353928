.email-outer-layout {
    min-height: 100vh;
    background-color: #f8f8fc;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 50px;
  }
  
  .email-inner-layout {
    width: 100%;
    max-width: 600px;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .email-inner-layout form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px;
  }
  
  .email-inner-layout form>div {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .email-inner-layout form label {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #7b61ff;
  }
  .validator{
    font-family: "Inter";
    font-style: normal;
    font-size: 12px;
    color: #ff6161;
  }
  
  .email-inner-layout form input,
  .email-inner-layout form textarea {
    font-family: "Inter";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #384860;
    width: 100%;
    border: 1px solid #384860;
    transition: 0.3s;
    border-radius: 10px;
    padding: 5px 20px;
  }
  
  .email-inner-layout form input:focus,
  .email-inner-layout form textarea:focus {
    outline: none;
  }
  
  .email-inner-layout form textarea {
    min-height: 200px;
  }
  
  .but {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 32px;
    gap: 8px;
    outline: none;
    border: none;
    width: fit-content;
    height: fit-content;
    background: #7b61ff;
    border-radius: 8px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #ffffff;
  }

  .file-upload {
  }
  
  .dropzone {
    border: 2px dashed #7b61ff;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    border-radius: 8px;
    background-color: #f1f1f1;
  }
  
  .dropzone p {
    font-size: 14px;
    color: #7b61ff;
  }
  
  .file-upload ul {
    margin-top: 10px;
    list-style-type: none;
    padding-left: 0;
  }
  
  .file-upload li {
    margin: 5px 0;
    font-size: 14px;
    color: #384860;
  }
  
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .email-outer-layout {
      padding: 30px;
    }
  
    .email-inner-layout {
      max-width: 90%;
      padding: 20px;
    }
  
    .email-inner-layout form {
      padding: 20px;
      gap: 15px;
    }
  
    .email-inner-layout form input,
    .email-inner-layout form textarea {
      font-size: 13px;
      padding: 5px 15px;
    }
  
    .but {
      padding: 14px 28px;
      font-size: 14px;
    }
  }
  
  @media (max-width: 480px) {
    .email-outer-layout {
      padding: 20px;
    }
  
    .email-inner-layout {
      max-width: 100%;
      padding: 15px;
    }
  
    .email-inner-layout form {
      padding: 15px;
      gap: 12px;
    }
  
    .email-inner-layout form input,
    .email-inner-layout form textarea {
      font-size: 12px;
      padding: 5px 12px;
    }
  
    .but {
      padding: 12px 24px;
      font-size: 13px;
    }
  }
  